import { executeApi, useApi } from '../../composables/useApi'
import { ref } from 'vue'

export default () => {
  const api = useApi()

  const myCareerList = ref([])

  const handleAddMyCareer = async (careerId) => {
    await executeApi(async () => {
      await api.careerSurvey.likeCareerSave({
        reqCareerLike: {
          careerId: careerId
        }
      })
      fetchMyCareerList()
    })
  }

  const handleDeleteMyCareer = async (careerId) => {
    await executeApi(async () => {
      await api.careerSurvey.likeCareerDelete({
        careerId:  careerId
      })
      fetchMyCareerList()
    })
  }

  const fetchMyCareerList = () => {
    executeApi(async () => {
      const { data } = await api.careerSurvey.getLikeCareerList()
      myCareerList.value = data
    })
  }


  return {
    myCareerList,
    handleAddMyCareer,
    handleDeleteMyCareer,
    fetchMyCareerList
  }
}
